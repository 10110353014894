import {Mailnews19} from "@react95/icons"

const Resume = {
    title: "resume.pdf",
    id: "resume",
    type: "resume",
    description: "resume.pdf",
    icon: <Mailnews19 variant="32x32_4"/>,
    content: [
        "https://i.imgur.com/AMSJ3sb.png",
    ],
  };
  
  export default Resume;