import React from 'react'
import './Screensaver.css'

const Screensaver = () => {
    
  return (
    <div class="screensaver-container">
        <img src="https://i.imgur.com/pLqNcG4.gif" alt="screensaver"></img>
        <h1>Wake up</h1>
    </div>
  )
}

export default Screensaver